export const sigmoid = x => 1.0 / (Math.exp(-x) + 1);
export const getHeatMap = colorScheme => {
  switch (colorScheme) {
    case "bw":
      return x => [x*255.0, x*255.0, x*255.0];

    default:
    case "color":
      // color heatmap
      return x => [
        x > 0.5 ? Math.cos(Math.PI * (x - 1))*255.0 : 0,
        Math.sin(Math.PI * x)*255.0,
        x < 0.5 ? Math.cos(Math.PI * x)*255.0 : 0
      ];
    case "original":
      return (x,rgb) => rgb;
  }
};
export const applyThreshold = (data, threshold, contrast, heatMap) => {
  let maxval = -1.0;
  let minval = 2.0;

  for (let i = 0; i < data.length; i += 4) {
    let v = ((data[i] - threshold) / 255.0) * (1 + contrast / 10.0);
    v = sigmoid(v);
    maxval = Math.max(maxval, v);
    minval = Math.min(minval, v);
  }

  for (let i = 0; i < data.length; i += 4) {
    let v = ((data[i] - threshold) / 255.0) * (1 + contrast / 10.0);
    let rgb = [data[i], data[i+1], data[i+2]];
    v = sigmoid(v);
    v = (v - minval) / (maxval - minval + 0.0001);
    v = heatMap(v, rgb);

    data[i + 0] = v[0];
    data[i + 1] = v[1];
    data[i + 2] = v[2];
  }
};

export const viewImage = dataUrl => {
  const image = new Image();
  image.src = dataUrl;

  const w = window.open("");
  w.document.write(image.outerHTML);
  document.body.appendChild(image);
};
