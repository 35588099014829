<template>
  <div class="pressure-map" style="height: calc(100% - 60px - 99px - 12px)">
    <div style="height: calc(100% - 76px)" class="pb-3 mb-3">
      <v-card
        :loading="!this.localRecord"
        :width="`${zoom}%`"
        style="transition: width 0.3s"
        class="mx-auto"
      >
        <template v-if="!!this.localRecord">
          <canvas
            id="canvas"
            style="width: 100%; vertical-align: bottom"
          ></canvas>
        </template>
        <v-card-text v-else class="text-center">{{
          $t("loading")
        }}</v-card-text>
        <v-overlay
          absolute
          v-if="
            !this.localRecord ||
              (this.localRecord.feetImage && (!feetImage || processing))
          "
        >
          <v-progress-circular indeterminate />
        </v-overlay>
      </v-card>
    </div>
    <v-toolbar
      v-if="localRecord"
      dark
      color="primary"
      class="mx-auto"
      style="position: sticky; bottom: 1.5rem"
      rounded
    >
      <v-slider
        :tick-labels="[$t('threshold')]"
        hide-details
        v-model="localRecord.threshold"
        @input="draw"
        class="ml-3"
        min="0"
        max="350"
      />
      <v-slider
        :tick-labels="[$t('contrast')]"
        hide-details
        v-model="localRecord.contrast"
        @input="draw"
        class="ml-3"
        min="1"
        max="160"
      />
      <v-slider
        :tick-labels="[$t('zoom')]"
        hide-details
        v-model="zoom"
        class="ml-3"
        min="30"
        max="100"
      />
      <v-select
        hide-details
        style="max-width: 200px"
        v-model="colorScheme"
        solo-inverted
        flat
        :items="['color', 'bw'].map(value => ({ text: $t(value), value }))"
      />
      <v-toolbar-items>
        <v-btn color="dark" text @click="save" :disabled="!changed" :loading="saving">
          {{ $t("save") }}
        </v-btn>
      </v-toolbar-items>
      <v-toolbar-items>
        <v-btn color="error" text @click="reset">
          {{ $t("discard") }}
        </v-btn>
      </v-toolbar-items>
      <v-spacer></v-spacer>
      </v-toolbar>
  </div>
</template>
<script>
import RecordMixin from "@/mixins/RecordMixin";
import { applyThreshold, getHeatMap } from "@/utils/imageUtils";

export default {
  name: "PressureMap",
  mixins: [RecordMixin],
  data: () => ({
    feetImage: null,
    colorScheme: "color",
    zoom: 60,
    processing: false
  }),
  mounted() {
    this.displayPressureMap();
  },
  computed: {
    canvas() {
      return document.getElementById("canvas");
    }
  },
  methods: {
    async displayPressureMap() {
      if (!this.localRecord || !this.localRecord.feetImage) return;
      if (!this.feetImage) await this.downloadFeetImage();

      if (!this.canvas) return;

      this.feetImage.imageObject = new Image();
      this.feetImage.imageObject.onload = () => {
        this.canvas.width = this.feetImage.imageObject.width;
        this.canvas.height = this.feetImage.imageObject.height;
        this.draw();
      };
      this.feetImage.imageObject.src =
        "data:image/jpeg;base64," + this.feetImage.data;
    },
    async downloadFeetImage() {
      const imageData = await this.$store.dispatch("download", {
        PatientID: this.localRecord.PatientID,
        FileName: this.localRecord.feetImage
      });
      this.feetImage = imageData;
    },
    async draw() {
      if (!this.canvas) return;
      this.processing = true;
      const heatMap = getHeatMap(this.colorScheme);
      const ctx = this.canvas.getContext("2d");
      
      ctx.translate(this.canvas.width, 0);
      ctx.scale(-1.0, 1.0);
      ctx.drawImage(this.feetImage.imageObject, 0, 0);
      ctx.scale(-1.0, 1.0);
      ctx.translate(-this.canvas.width, 0.0);
      
      const imageData = ctx.getImageData(
        0,
        0,
        this.canvas.width,
        this.canvas.height
      );

      applyThreshold(
        imageData.data,
        this.localRecord.threshold,
        this.localRecord.contrast,
        heatMap
      );
      ctx.putImageData(imageData, 0, 0);
      this.processing = false;
    },
    reset() {
      this.colorScheme = "color";
      this.localRecord.threshold = 127;
      this.localRecord.contrast = 80;
      this.zoom = 60;
    }
  },
  watch: {
    colorScheme() {
      this.draw();
    }
  }
};
</script>
